/* Module: S */
#main {
    overflow-x: hidden;
    .container >*:last-child() {
        border-bottom: 50px solid transparent;
    }
}

main > .container,
form > .container {
    padding-left: 0;
    padding-right: 0;
    max-width: pxToRem($width-desktop);
    margin-left: auto;
    margin-right: auto;
}

#main.main--home {
padding-bottom: pxToRem(25);
    overflow-x: visible;
}

.page--contact #main {
    padding-bottom: pxToRem(25);
}

.page--service-details main > .container {
    font-size: pxToRem(16);
    line-height: 2;
}

body.home {
    overflow-x: hidden;
}