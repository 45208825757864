/* Module: S */
.hero-text {
  background-image: url({{file_dest}}/hero-text--home.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: pxToRem(60);
  padding-bottom: pxToRem(80);
}

.card-grid + .hero-text {
  margin-top: 0;
}

.hero-text__container {
  max-width: pxToRem($width-desktop);
  margin-left: auto;
  margin-right: auto;
}

.hero-text__subtitle {
}

.hero-text__title {
  font-size: pxToRem(38);
  width: pxToRem(640);
  a {
    font-size: pxToRem(38);
  }
}


.hero-text__title--normal {
  font-size: pxToRem(30);
  line-height: 1.33;
  width: pxToRem(480)
}

.hero-text__description {
  font-size: pxToRem(16);
  line-height: pxToRem(32);
  width: pxToRem(480)
}

.hero-text__more {
}

.hero-text__more-text {
}

.svg-icon.icon-arrow-right-thin {
}