/* Module: S */
.header-nav {
  position: absolute;
  top: pxToRem(45);
  left: pxToRem(0);
  z-index: 2;
  padding-left: pxToRem(254);
  width: 100%;
}

.header-nav-l1__list {
  display: flex;
  position: static;
}

li.header-nav-l1__item {
  padding-left: pxToRem(22);
  padding-right: pxToRem(22);
  display: flex;
}

.header-nav-l1__item.active {
  background-color: transparent;
}

.header-nav-l1__item.active,
.header-nav-l1__item:hover {
  &::after {
    opacity: 1;
  }
}

.header-nav-l1__item.header-nav-l1__item--services::after {
  width: calc(100% - 7px);
}

.header-nav-l1__item::after {
  background-color: $lipstick;
  opacity: 0;
  content: "";
  display: block;
  width: 100%;
  height: pxToRem(3);
  position: relative;
  z-index: 2;
  transition: 0.2s opacity ease-in;
}

.header-nav-l1__link {
  height: auto;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 1;
  color: $black-four;
}

.main-header--home .header-nav-l1__link {
  color: $white;
}

.main-header--submenu-open .header-nav-l1__link {
  color: $white;
}

.header-nav-l1__item--has-child .header-nav-l1__link:hover {
  color: $white;
}

.header-nav-l1__item--has-child:hover > ul {
  display: block;
}

.header-nav-l1__item--has-child:hover::before {
  content: "";
  display: block;
  width: 100vw;
  height: pxToRem(800);
  position: absolute;
  top: pxToRem(-67);
  left: 50%;
  transform: translateX(-50vw);
  z-index: 0;
  background-image: linear-gradient(
    to top,
    $light-navy-two,
    rgba(21, 57, 126, 0.9)
  );
}

.header-nav-l2__toggle {
  height: auto;
  transform: rotate(0.25turn);
  width: pxToRem(25);
  pointer-events: none;
}

.header-nav-l2__toggle .icon-dash-right,
.header-nav-l1__item .header-nav-l2__toggle .icon-dash-right,
.header-nav-l1__item.active .header-nav-l2__toggle .icon-dash-right {
  fill: $black-four;
  stroke: $black-four;
}

.main-header--home .header-nav-l1__item .header-nav-l2__toggle .icon-dash-right,
.main-header--home
  .header-nav-l1__item.active
  .header-nav-l2__toggle
  .icon-dash-right,
.main-header--submenu-open
  .header-nav-l1__item
  .header-nav-l2__toggle
  .icon-dash-right,
.main-header--submenu-open
  .header-nav-l1__item.active
  .header-nav-l2__toggle
  .icon-dash-right {
  fill: $white;
  stroke: $white;
}

.header-nav-l2__list {
  position: absolute;
  width: auto;
  left: calc(50% - #{pxToRem(394)});
  top: pxToRem(70);
  background-color: transparent;
  width: pxToRem(430);
}

.header-nav-l2__item--go-back,
.header-nav-l3__item--go-back {
  display: none;
}

.header-nav-l2__go-back.header-nav__go-back {
}

.svg-icon.icon-arrow-right {
}

.header-nav-l2__item {
  display: block;
}

.header-nav-l2__item::after {
  content: "";
  display: block;
  width: 100%;
  height: pxToRem(2);
  background-color: $light-grey-blue;
  opacity: 0;
  margin-top: pxToRem(10);
}

.header-nav-l2__item:hover::after {
  opacity: 1;
}

.header-nav-l2__link {
  font-size: pxToRem(20);
  padding-right: 0;
  padding-left: 0;
}

.header-nav-l3__toggle {
  display: none;
}

.header-nav-l2__item:hover .header-nav-l3__list {
  display: block;
  padding-left: pxToRem(40);
  height: 100%;
}
.header-nav-l3__list {
  background-color: transparent;
  position: absolute;
  left: pxToRem(429);
  top: 0;
}

.header-nav-l3__item {
}

.header-nav-l3__link {
  font-size: pxToRem(20);
  padding-left: 0;
}
