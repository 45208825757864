/* Module: S */
.video__container {
    margin: pxToRem(32) 0;
    position: relative;
}

p + .video__container {
    margin: pxToRem(45) 0;
}

.video__header {
    color: $light-navy;
    font-size: pxToRem(26);
    line-height: pxToRem(34);
    font-family: $font-open-sans;
    font-weight: normal;
}

.video__text {
    background-color: $white;
    padding-top: 40px;
    padding-bottom: 40px;
    padding: pxToRem(40) pxToRem(20);
}

.video__note {
    color: $rouge;
    font-size: pxToRem(20);
    line-height: pxToRem(20);
    font-family: $font-neustadt;
}

.video__thumbnail-wrapper {
    position: relative;
    font-size: 0;
}

.video_thumbnail {
    display: block;
}

.video__popup {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    text-align: right;
    position: absolute;
    top: -57px;
    left: 0;
    right: 0;
    z-index: 5;
    transition: all .35s ease-in-out;
}

.video__popup.visible {
    visibility: visible;
    opacity: 1;
}

.video__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.video__popup--background {
    display: none;
    background-color: $black;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position:fixed;
    top:0;
    left: 0;
    z-index: 4;
    transition: none;
}


main > .container button.video__open {
    border-radius: 50%;
    border: 4px solid $white;
    width: 48px;
    height: 48px;
    position: absolute;
    left: 0;
    bottom: pxToRem(-27);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    &:after {
        content:"";
        position: absolute;
        top: 13px;
        left: 16px;
        width: 0; 
        height: 0; 
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 10px solid $white;
        }
}

main > .container button.video__close {
    border-radius: 50%;
    border: 3px solid $white;
    width: 38px;
    height: 38px;
    position: relative;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    & .icon-close {
        fill: $white;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 6px;
        left: 7px;
    }
}

.video__title-box {
    background-color: $light-navy;
    text-transform: uppercase;
    font-family: $font-open-sans;
    font-size: pxToRem(12);
    font-weight: bold;
    line-height: 1.67;
    color: $white;
    padding: pxToRem(20);
}