/* Module: S */
.services-navigation {
  background-color: $light-navy;
  height: pxToRem(70);
  overflow: hidden;
  display: none;
}

.services-navigation__list {
  display: block;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  white-space: nowrap;
}

.services-navigation__item {
  display: none;
  padding-left: pxToRem(16);
  padding-right: pxToRem(16);
}

.services-navigation__item.services-navigation__item--current {
  display: inline-block;
}

.services-navigation__link {
  color: $very-light-pink;
  font-size: pxToRem(16);
  font-weight: 600;
  line-height: pxToRem(70);
  text-decoration: none;
}

.services-navigation__item--current .services-navigation__link {
  display: block;
  text-decoration: underline;
}
