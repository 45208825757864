/* Module: S */
.home-hero {
  margin-top: $home-hero__margin-offset;
  position: relative;
}

.home-hero::after {
  background-image: linear-gradient(to top, $black-0, $black);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}

.home-hero__video-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.home-hero__image,
.home-hero__video {
  height: pxToRem(496);
  object-fit: cover;
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
}

.home-hero__video iframe {
  width: 100%;
  height: 100%;
}

.home-hero__text {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  left: 0;
  margin: 0;
  padding-left: pxToRem($padding-mobile);
  padding-right: pxToRem($padding-mobile);
  position: absolute;
  top: 0;
  text-shadow: 0 0 22px $black;
  width: 100%;
  font-size: pxToRem(42);
  font-weight: 400;
  z-index: 1;
}
