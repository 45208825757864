/* Module: S */
.search-filters + .container {
  margin-top: 0;
}

.search-filters__filter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-filters__filter-input-wrapper {
  position: relative;
  top: pxToRem(-2);
}

.search-filters__container {
  flex-wrap: nowrap;
  max-width: pxToRem($width-desktop);
  margin-left: auto;
  margin-right: auto;
}

.search-filters__label {
  display: block;
  font-size: pxToRem(20);
  position: relative;
  top: pxToRem(3);
  margin-right: pxToRem(15);
}

.search-filters__filter {
  .selectize-control {
    width: pxToRem(420);
    top: pxToRem(1);
  }
  .selectize-input.full {
    font-size: pxToRem(20);
  }
  .selectize-dropdown {
    font-size: pxToRem(20);
  }
  .selectize-dropdown .option {
    padding: pxToRem(22) pxToRem(17) pxToRem(19);
  }
}