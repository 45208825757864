/* Module: S */
.footer-content {
  display: flex;
  justify-content: space-between;
  background-color: $dark-indigo;
  flex-wrap: wrap;
}

.footer-content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.footer-links {
  width: 50%;
  padding-left: pxToRem(20);
  padding-top: pxToRem(20);
  padding-bottom: pxToRem(40);
}

.footer-links__item {
  height: pxToRem(50);
  display: flex;
  align-items: center;
}

.footer-links__link {
  font-size: pxToRem(16);
  text-transform: uppercase;
  color: $white;
  text-decoration: none;
}

ul.footer-social {
  width: 50%;
  padding-right: pxToRem(20);
  padding-left: pxToRem(20);
  padding-top: pxToRem(20);
  padding-bottom: pxToRem(40);
}

.footer-social__item {
  height: pxToRem(50);
  display: flex;
  align-items: center;
  justify-content: start;
}

.footer-social__item .icon-youtube {
  width: pxToRem(25);
  height: pxToRem(17);
}

.footer-social__item .icon-linkedin {
  width: pxToRem(26);
  height: pxToRem(25);
}

.footer-social__text {
  padding-left: pxToRem(10);
}

.footer-social__link {
  font-size: pxToRem(16);
  color: $white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer__go-top {
  display: none;
}