/* Module: S */
.search-pagination {
  text-align: center;
  padding-bottom: pxToRem(50);
  margin-top: pxToRem(40);
}

.pagination {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.pagination__item {
  display: inline-block;
  padding-left: pxToRem(7);
  padding-right: pxToRem(7);
}

.pagination__page {
  font-size: pxToRem(16);
  position: relative;
  font-weight: bold;
  color: $black-two;
}

.pagination__page::after {
  content: '';
  display: block;
  width: pxToRem(9);
  height: pxToRem(3);
  background-color: $lipstick;
  left: 0;
  bottom: pxToRem(-10);
}

.pagination__link {
  text-decoration: none;
  font-size: pxToRem(16);
  font-weight: bold;
  color: $black-two;
}

.pagination__more {
  font-size: pxToRem(16);
  position: relative;
  font-weight: bold;
}

.pagination__prev,
.pagination__next {
}

.pagination .icon-arrow-right-thin {
  width: pxToRem(20);
  height: pxToRem(14);
}

.pagination__link.pagination__prev .icon-arrow-right-thin {
  transform: rotate(0.5turn);
}