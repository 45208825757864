/* Module: S */
.text-image {
    display: flex;
    min-height: pxToRem(385);
    margin: pxToRem(80) 0;
    width: 100%;
}

.text-image--right {
    flex-direction: row-reverse;
}

.text-image--no-background {
    justify-content: space-between;
    .text-image__text {
        padding: 0;
    }
    &.text-image--right .text-image__text {
        //display: flex;
        justify-content: space-between;
        padding: 0 pxToRem(50) pxToRem(30) pxToRem(30);
        width: 50%;
    }

    .text-image__image {
        width: pxToRem(580);
    }

    .text-image__text {
        width: pxToRem(551);
    }
}

.text-image__text {
    padding: pxToRem(30) pxToRem(50) pxToRem(30) pxToRem(30);
    width: 50%;
    p {
        font-size: pxToRem(16);
        line-height: pxToRem(32);
        margin: pxToRem(50) 0;
    }
    p:first-of-type {
        margin-top: 0;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}

.text-image__header {
    font-size: pxToRem(30);
    line-height: pxToRem(40);
    margin-top: pxToRem(10);
}

.text-image__image {
    width: 50%;
    margin-left: 0;
    img {
        height: 100%;
    }
}