/* Module: S */
// .form {
// }

// .form {
//   .field-label {
//     float: left;
//     padding: pxToRem(14) 0;
//     max-width: pxToRem(150);
//   }
//   .field-daterange {
//     float: right;
//     width: 66%;
//     span {
//       width: 12%;
//     }
//   }

//   .field--date-from,
//   .field--date-to {
//     font-size: pxToRem(14);
//   }
// }
