/* Module: S */
.hero-text {
  background-image: linear-gradient(to top, $light-navy-0, $dark-indigo-two), linear-gradient(273deg, $light-navy, $dark-indigo);
  padding: pxToRem(40) pxToRem($padding-mobile) pxToRem(50);
  color: $white;
  margin-top: pxToRem(40);
  width: 100vw;
  margin-left: calc((100vw - 100%) / -2);
}

.hero-text__subtitle {
  font-size: pxToRem(20);
  font-weight: normal;
  font-family: $font-neustadt;
}

.hero-text__title {
  font-family: $font-open-sans;
  font-weight: 300;
  line-height: 1.43;
  font-size: pxToRem(28);
  a {
    color: $white-two;
    text-decoration: none;
    font-family: $font-open-sans;
    font-weight: 600;
    line-height: 1.43;
    font-size: pxToRem(28);
    position: relative;
    display: inline-block;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: pxToRem(2);
      background-image: linear-gradient(to right, $watermelon, $carmine);
      position: absolute;
      left: 0;
      bottom: pxToRem(2);
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: pxToRem(2);
      background-image: none;
      background-color: $white;
      position: absolute;
      left: 0;
      bottom: pxToRem(2);
      opacity: 0;
      transition: .2s opacity ease-in;
    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}

.hero-text__title strong {
  font-weight: 400;
  position: relative;
}

.hero-text__title strong::after {
  content: '';
  display: block;
  width: 100%;
  height: pxToRem(2);
  background-image: linear-gradient(to right, $watermelon, $carmine);
  position: absolute;
  left: 0;
  bottom: pxToRem(2);
}

.hero-text__title--normal {
  font-family: $font-open-sans;
  font-weight: normal;
  font-size: pxToRem(24);
  line-height: 1.33;
}

.hero-text__description {
  font-size: pxToRem(14);
  line-height: pxToRem(28);
  color: $white;
  font-family: $font-open-sans;
}

.hero-text__more,
.hero-text__link {
  color: $white;
  font-size: pxToRem(12);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-family: $font-open-sans;
}

.hero-text__more-text:hover {
  text-decoration: underline;
}

.hero-text .svg-icon.icon-arrow-right-thin {
  width: pxToRem(18);
  height: pxToRem(18);
  position: relative;
  top: pxToRem(5);
  left: pxToRem(7);
}

.hero-text .svg-icon.icon-arrow-left-thin {
  width: pxToRem(18);
  height: pxToRem(18);
  position: relative;
  top: pxToRem(5);
  transform: rotate(180deg);
  margin-right: pxToRem(10);
}

.link--external:after {
  content: url('{{file_dest}}/link-external-red.svg');
  display: inline-block;
  position: relative;
  top: pxToRem(2);
  right: pxToRem(-10);
}