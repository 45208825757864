/* Module: S */
.rich-text {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    padding: pxToRem(15) pxToRem($padding-mobile) pxToRem(50);
    width: 100vw;
    margin-left: calc((100vw - 100%) / -2);
}

.rich-text__container-header {
    color: $light-navy;
    font-size: pxToRem(26);
    line-height: pxToRem(34);
    font-family: $font-open-sans;
    font-weight: normal;
    margin-top: 0;
}

.rich-text__container--right {
    p {
        color: $black-two;
        font-size: pxToRem(14);
        line-height: pxToRem(28);
        font-family: $font-open-sans;
        margin: pxToRem(20) 0;
    }
}

.rich-text + div {
    margin-top: 0;
}

.rich-text__container--left {
    text-align: right;
    padding-right: pxToRem(27);
    border-right: solid 2px $light-navy;
}

.rich-text__container--right {

}

.rich-text__author {
    display: flex;
    align-items: center;
    margin-top:pxToRem(36);
}

.rich-text__author--image-wrapper {
    width: pxToRem(80);
    height: pxToRem(80);
    border-radius: 50%;
    border: 4px solid $white;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.rich-text_author--about {
    display: flex;
    flex-direction: column;
    margin-left: pxToRem(15);
    color: $light-navy;
    font-family: $font-open-sans;
    font-size: pxToRem(14);
    font-weight: normal;
    line-height: 1.57;
}

.rich-text_author--name {
    font-weight: 600;
}