/* Module: S */
.back-to {
  width: 100%;
  border-top: 2px solid $very-light-pink-six;
  padding: pxToRem(20) 0;
}

.back-to__link {
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.67;
  color: $black-two;
  text-decoration: none;
}

.icon-arrow-left-thin {
  height: pxToRem(18);
  position: relative;
  top: pxToRem(5);
  width: pxToRem(18);
  transform: rotate(180deg);
  margin-right: pxToRem(10);
}