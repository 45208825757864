/* Module: S */
.inside-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: pxToRem(20) 0;
    border-bottom: pxToRem(2) solid $very-light-pink-six;
    margin-bottom: pxToRem(40);
}

.main--home .inside-top-bar {
    display: none;
}

.page--news-events .inside-top-bar {
    display: none;
}