/* Module: S */
.rich-text {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    padding-top: pxToRem(30);
    padding-bottom: pxToRem(80);
}
  
.rich-text__container {
    max-width: pxToRem($width-desktop);
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.rich-text__container--left,
.rich-text__container--right {
    width: 50%;
}

.rich-text__container--left {
    text-align: right;
    padding-right: pxToRem(40);
}

.rich-text__container--right {
    border-left: solid 2px $light-navy;
    padding-left: pxToRem(40);
}

.rich-text__container-header {
    font-size: pxToRem(44);
    line-height: pxToRem(60);
}

.rich-text__container--right {
    p {
        font-size: pxToRem(16);
        line-height: pxToRem(32);
        margin: pxToRem(20) 0;
    }
    p:first-of-type {
        margin-top: 0;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}

.rich-text__author {
    margin-left: 50%;
}

.rich-text_author--about {
    font-size: pxToRem(16);
    line-height: 1.5;
}