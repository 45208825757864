/* Module: S */
.inside-widget {
  width: pxToRem(380);
  margin-left: pxToRem(57);
  margin-right: 0;
  float: right;
  margin-bottom: pxToRem(72);
  padding-left: pxToRem(30);
  padding-right: pxToRem(30);
}

.inside-widget.inside-widget--aside {
  float: none;
  margin-left: 0;
  margin-bottom: pxToRem(50);
}

.inside-widget__person-wrapper {
}

.inside-widget__person-image {
}

.inside-widget__name {
}

.inside-widget__title {
}

.inside-widget__phone {
}

.inside-widget__contact-list {
}

.inside-widget__contact-item {
}

.svg-icon.icon-contact-mail {
}

.inside-widget__contact-link {
}

.svg-icon.icon-contact-linkedin {
}