/* Module: S */
.share-bar .share-bar__button {
  background-color: transparent;
  border: 0;
  font-size: pxToRem(12);
  line-height: pxToRem(20);
  color: $black-two;
  font-weight: bold;
  font-family: $font-open-sans;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: pxToRem(35);
}

.share-bar .share-bar__button:hover,
.share-bar .share-bar__button:focus {
  background-color: transparent;
}

.share-bar {
  display: flex;
  position: relative;
}

.share-bar__button .icon-download {
  width: pxToRem(17);
  height: pxToRem(19);
  margin-left: pxToRem(11);
}

.share-bar__button .icon-share {
  width: pxToRem(17);
  height: pxToRem(18);
  margin-left: 17px;
}


.share-bar__list {
  visibility: hidden;
  opacity: 0;
  list-style-type: none;
  padding-left: 0;
  padding: pxToRem(5) pxToRem(15);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 20px;
  right: 0;
  background-color: $white;
  transition: all .3s ease-in-out;
  display: block;
}

.share-bar__list.visible {
  visibility: visible;
  opacity: 1;
}

.share-bar__list-item {
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
  &:last-of-type {
    border-bottom: 0;
  }
}

.share-bar__link {
  color: $black-two;
  text-decoration: none;
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  line-height: pxToRem(20);
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: align-content;
}

.icon-share-linkedin {
  fill: $lipstick-two;
  width: 17px;
  height: 17px;
  margin-right: 8px;
}

.icon-share-mail {
  fill: $lipstick-two;
  width: 20px;
  height: 17px;
  margin-right: 10px;
}