/* Module: S */
.home-hero {
    margin-top: pxToRem(-114);
}

.home-hero__image,
.home-hero__video {
    height: pxToRem(671);
    width: 100%;
}   

.home-hero__text {
    font-size: pxToRem(70);
    left: 50%;
    width: pxToRem(580);
    transform: translateX(#{pxToRem(-580)});
    padding-left: 0;
    padding-right: 0;
    line-height: 1.29;
}