/* Module: S */
.text-on-image {
  color: $white;
  margin-left: calc((100vw - 100%) / -2);
  overflow: auto;
  width: 100vw;
}
.text-on-image__img {
  width: 100vw;
  max-width: none;
  display: block;
  //object-fit: cover;
  background-size: cover;
  background-position: center center;
}

.text-on-image__box {
  background-color: $light-navy;
  padding: pxToRem(30) pxToRem(20);
  width: 100vw;
}

.text-on-image__title {
  font-size: pxToRem(24);
  line-height: 1.67;
  font-weight: normal;
}

.text-on-image__description {
  font-size: pxToRem(14);
  line-height: 2;
}
