/* Module: S */
.main-header,
.main-header--home.main-header--mobile-menu-active {
  background-color: #fff;
  height: $header__height;
  padding-top: pxToRem(10);
  position: relative;
  z-index: 2;
}

.main-header--home {
  background-color: transparent;
}

.header-content {
  position: relative;
}

.header-logo__wrapper {
  color: $light-navy;
  display: inline-block;
  padding: 0 14px;
  position: relative;
  z-index: 4;

  .main-header--home &,
  .main-header--submenu-open & {
    color: $white;
  }

  .main-header--mobile-menu-active & {
    color: $light-navy;
  }
}

.header-logo__image {
  height: $logo__height;
  width: $logo__width;
}

.header-links {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-links__item--active {
  background-color: $dark-indigo;
}

.header-links__link {
  font-size: pxToRem(16);
  height: pxToRem(76);
  display: flex;
  align-items: center;
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);
  color: $white;
  text-decoration: none;
}

.main-header--submenu-open .header-links__link {
  color: $white;
}

.header-links__item::after {
  content: '';
  display: block;
  width: 100%;
  height: pxToRem(2);
  background-color: $dark-indigo;
  opacity: 0.5;
}

.header__mobile-menu-toggle {
  position: absolute;
  right: pxToRem(20);
  top: pxToRem(17);
  background: transparent;
  border: none;
  padding: 0;
  display: block;
}

.header__mobile-menu-toggle .icon-menu {
  width: pxToRem(24);
  height: pxToRem(17);
  fill: $light-navy;
}

.main-header--home .header__mobile-menu-toggle .icon-menu {
  fill: $white;
}

.header__mobile-menu-toggle[aria-expanded='true'] .icon-menu {
  display: none;
}

.header__mobile-menu-toggle .icon-close {
  display: none;
}

.header__mobile-menu-toggle[aria-expanded='true'] .icon-close {
  fill: $light-navy;
  display: inline-block;
  width: pxToRem(24);
  height: pxToRem(18);
}

.header__mobile-menu-toggle[aria-expanded='true'] + .header__mobile-menu {
  display: block;
}

.header__mobile-menu {
  display: none;
  background-color: $dark-indigo;
  position: absolute;
  top: $header__height - $header__padding-top;
  width: 100%;
}

.header__mobile-wrapper {
  background-image: linear-gradient(to top, $light-navy-two, $light-navy);
}

.header-section--hidden {
  display: none;
}

.main-header--mobile-menu-active + main#main {
  position: relative;
}

.main-header--mobile-menu-active + main#main::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: $black;
  z-index: 1;
  opacity: 0.9;
}
