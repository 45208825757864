/* Module: S */
main > .container {
    form {
        padding-top: pxToRem(10);
        padding-bottom: pxToRem(10);
    }
    fieldset {
        margin: pxToRem(10) 0 0;
        padding: 0;
        border: 0;
        display: block;
    }
    legend {
        font-size: pxToRem(18);
        font-weight: 600;
        margin: pxToRem(20) 0 pxToRem(10);
    }
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    select,
    textarea {
        border: pxToRem(1) solid $very-light-pink-two;
        margin-top: pxToRem(10);
        margin-bottom: pxToRem(10);
        max-width: 100%;
        width: 100%;
        padding: pxToRem(8);
    }
    input[type="submit"],
    button {
        background-color: $light-navy-three;
        color: $white;
        border: none;
        text-transform: uppercase;
        font-size: pxToRem(14);
        font-weight: 600;
        padding: pxToRem(12) pxToRem(16);
        margin-bottom: pxToRem(10);
        margin-top: pxToRem(10);
        &:hover, 
        &:focus {
           background-color: $dark-indigo-five;
        }
    }
    select {
        height: pxToRem(40);
    }
    .sq-form-question-datetime select {
        height: auto;
    }
    fieldset ul {
        margin: 0;
        padding: 0 0 pxToRem(10);
        list-style-type: none;
    }
    fieldset ul li {
        margin-top: pxToRem(12);
        margin-bottom: pxToRem(12);
    }
    fieldset ul li::before {
        content: '';
        display: none;
    }
    input[type="checkbox"],
    input[type="radio"] {
        + label {
            padding-left: pxToRem(5);
        }
    }
}