/* Module: S */
.content{
    margin-bottom: pxToRem(75);   
 }
 .sidebar{
    margin-bottom: pxToRem(75);
     
 }
@media screen and (min-width: 1024px){
  
    .grid{
        display:grid;
        grid-template-columns:auto pxToRem(380); 
        grid-template-areas:"content sidebar";
        gap:75px;
     
     }
     .content{
         grid-area:content;
         > *:first-child{
             margin-top:0;
         }
         
     }
     .sidebar{
         grid-area:sidebar;
     }
   
  
  }