/* Module: S */
.rich-quote {
  margin-top: pxToRem(70);
  display: flex;
  margin-left: pxToRem(-$padding-mobile);
  margin-right: pxToRem(-$padding-mobile);
}

.rich-quote__details {
  background-color: $very-light-pink-three;
  color: $black-two;
  padding: pxToRem($padding-mobile);
  position: relative;
  text-align: center;
  padding-top: pxToRem(59)
}

.rich-quote__thumbnail {
  border: solid pxToRem(4) $white;
  border-radius: pxToRem(50);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
  height: pxToRem(98);
  left: calc(50% - #{pxToRem(49)});
  position: absolute;
  top: pxToRem(-49);
  width: pxToRem(98);
}

.rich-quote__description {
  font-family: $font-open-sans;
  font-size: pxToRem(14);
  line-height: 2;
  margin-bottom: pxToRem(25);
}

.rich-quote__author {
  color: $brownish-grey;
  display: block;
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  font-weight: bold;
  line-height: 1.67;
  margin-bottom: pxToRem(15);
  text-transform: uppercase;
}
