/* Module: S */
.contact-us__form {
  display: flex;
  width: 100%;
}
.contact-us__header {
  width: 50%;
}

.contact-us__header span {
  font-size: pxToRem(30);
}

.contact-us__form-wrapper {
  width: 50%;
}

.contact-us__form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: pxToRem(15);
}

.contact-us__form-input-wrapper {
  width: calc(50% - #{pxToRem(10)});
}

.contact-us__form-wrapper label {
  display: block;
}

.container .contact-us__form-wrapper input[type=text],
.container .contact-us__form-wrapper textarea {
}

.container .contact-us__form-wrapper input[type=text] {
}

.container .contact-us__form-wrapper textarea {
}

.contact-summary {
}

.container .contact_submit {
}

.container .contact_submit:hover,
.container .contact_submit:focus {
}

.contact_submit span {
}

.contact_submit .icon-arrow-right-thin {
}