/* Module: S */
.header-search__toggle {
  background-color: transparent;
  border: none;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.header-search__toggle .icon-search {
  height: pxToRem(18);
  width: pxToRem(17);
  fill: $light-navy;
}

.main-header--home .icon-search {
  fill: $white;
}

.header-search__toggle .icon-close {
  display: none;
}

.header-search__toggle[aria-expanded=true] .icon-close {
  display: block;
  fill: $rouge;
  height: pxToRem(18);
  width: pxToRem(17);
}

.header-search__toggle[aria-expanded=true] .icon-search {
  display: none;
}

.header-search__toggle[aria-expanded=true] + .header-search__form-wrapper {
  display: block;
}

.header-search__toggle[aria-expanded=true] + .header-search__form-wrapper::before {
  background-image: linear-gradient(to top, $light-navy-two, $light-navy);
  content: '';
  display: block;
  height: pxToRem(671);
  left: 50%;
  position: absolute;
  top: pxToRem(-22);
  transform: translateX(-50%);
  width: 100vw;
  z-index: 0;
}

.header-search__form-wrapper {
  display: none;
}

.header-search__form {
  border-bottom: pxToRem(2) solid $light-navy;
  display: flex;
  height: pxToRem(90);
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  position: relative;
  top: pxToRem(191);
  width: pxToRem($width-desktop);
  z-index: 1;
}

.header-search__input {
  color: $white;
  font-size: pxToRem(50);
  padding-left: 0;
}

.header-search__input::placeholder {
  color: $white;
  visibility: visible;
}

.header-search__input::-moz-placeholder {
  color: $white;
  visibility: visible;
  opacity: 1;
}

.header-search__input::-ms-input-placeholder {
  color: $white;
  visibility: visible;
}

.header-search__submit {
  align-items: center;
  display: flex;
  height: pxToRem(90);
  justify-content: center;
  width: pxToRem(50);
}

.svg-icon.icon-search {
  height: pxToRem(17);
  width: pxToRem(26);
}
