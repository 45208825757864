/* Module: S */
.stats-bar {
  width: pxToRem($width-desktop);
  display: block;
  background-color: $white;
  height: pxToRem(154);
}

.stats-bar--full-width {
  background-color: $very-light-pink-three;
  width: 100vw;
  margin-left: calc((100vw - 100%) / -2);
  height: auto;

  .stats-bar__list {
    width: pxToRem($width-desktop);
    margin-left: auto;
    margin-right: auto;
  }
}

.stats-bar__list {
  display: flex;
}