/* Module: S */
.inside-widget-with-thumbnail {
  background-color: $light-navy;
  color: $white;
  padding: pxToRem(23) pxToRem($padding-mobile) pxToRem(33);
  font-family: $font-open-sans;
}

.inside-widget-with-thumbnail__details {
  display: flex;
  align-items: center;
  padding-top: pxToRem(16);
  font-size: pxToRem(14);
  line-height: 1.43;
  margin-bottom: pxToRem(30);
}

.inside-widget-with-thumbnail__header {
  margin: 0;
  font-weight: 400;
  font-size: pxToRem(24);
}
.inside-widget-with-thumbnail__title {
  margin: 0;
  font-weight: 400;
  font-size: pxToRem(20);
}

.inside-widget-with-thumbnail__image {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  height: auto;
  width: pxToRem(400);
  margin-right: pxToRem(20);
  cursor: pointer;
  margin: pxToRem(24) 0;
}

.icon-download {
  width: pxToRem(19);
  height: pxToRem(15);
  fill: $white;
}

.inside-widget-with-thumbnail__download-link {
  font-weight: 600;
  text-decoration: none;
  color: $white;
}

.svg-icon {
  width: pxToRem(18);
  height: pxToRem(26);
  margin-right: pxToRem(8);
  flex-shrink: 0;
  position: relative;
  // top: pxToRem(7);
  fill: #e3183d;
}
