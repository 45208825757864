/* Module: S */
.home-search__form .twitter-typeahead {
	display: block;
	width: 100%;
}

.twitter-typeahead {
	display: block;
	width: 100%;
}

.twitter-typeahead .home-search__input {
	width: 100%;
}

.quick-search__form .twitter-typeahead {
	order: 2;
    display: block;
    width: 100%;
}

.tt-menu {
	border: 1px solid $greyish-brown;
	border-top: none;
	width: 100%;
	background-color: $white;
}

.search-suggest-result__container,
.search-suggest-result-large__container,
.search-suggest-result-standard__container {
	display: block;
	border-top: 1px solid $brown-grey;
	padding: pxToRem(15) pxToRem(15) 0 pxToRem(15);
	margin: 0 0 pxToRem(15) 0;
	text-align: left;
	text-decoration: none;
	color: $black-two;

	&:first-child {
		border-top: none;
	}

	&.tt-cursor,
	&:hover,
	&:focus {
		text-decoration: underline;

		// .search-suggest-result-large__title {
		// 	text-decoration: underline;
		// }
	}
}

.search-suggest-result-standard__container {
	margin: 0;
	padding-bottom: pxToRem(15);
	cursor: pointer;
}

.search-suggest-result-large__title {
	font-size: pxToRem(16);
	line-height: 1.33;
	margin: 0;
	color: $black-two;
}

.search-suggest-result-large__summary {
	margin: pxToRem(10) 0 0 0;
	color: $greyish-brown;
	font-size: pxToRem(16);
	line-height: 1.5;
	display: none;
}