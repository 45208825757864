/* Module: S */
footer.footer {
    background-color: $dark-indigo;
    height: pxToRem(186);
    position: relative;
    margin-top: 0;
}

.footer.footer::before {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(2);
    background-image: linear-gradient(to right, $lipstick, $rouge);
    position: absolute;
    left: 0;
    top: pxToRem(-2);
    z-index: 1;
}

.footer.footer::after {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(50);
//    background-color: $very-light-pink-three;
    position: absolute;
    top: pxToRem(-50);
    left: 0;
    z-index: 0;
}

.footer-content {
    width: pxToRem($width-desktop);
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

ul.footer-links {
    width: pxToRem(560);
    padding-top: pxToRem(57);
    padding-left: 0;
    padding-bottom: pxToRem(30);
}

.footer-links__item {
    display: inline-block;
    padding-right: pxToRem(43);
}

ul.footer-social {
    position: absolute;
    top: pxToRem(100);
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.footer-social__link {
    display: flex;
    padding-right: pxToRem(58);
    align-items: center;
}

.footer__go-top {
    display: block;
    height: 100%;
    margin-top: pxToRem(67);
    text-decoration: none;
}

.footer__go-top .icon-gotop {
    width: pxToRem(26);
    height: pxToRem(26);
}

.footer__go-top .footer__go-top-text {
    font-size: pxToRem(12);
    text-transform: uppercase;
    color: $white;
    display: block;
    font-weight: 600;
    font-family: $font-open-sans;
    text-align: center;
    padding-top: pxToRem(3);
}