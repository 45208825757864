/* Module: S */
.text-on-image {
  width: 100vw;
  margin-left: calc((100vw - 100%) / -2);
  position: relative;
}

.text-on-image--left .text-on-image__box {
  margin-left: calc(50% - 590px);
}

.text-on-image__img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.text-on-image__box {
  max-width: 590px;
  background-color: $light-navy;
  padding: pxToRem(30) pxToRem(50) pxToRem(30) pxToRem(30);
  margin-top: pxToRem(160);
  margin-bottom: pxToRem(160);
  margin-left: 50%;
  position: relative;
  z-index: 0;
}

.text-on-image__title {
  font-size: pxToRem(30);
  line-height: 1.33;
}

.text-on-image__description {
  font-size: pxToRem(16);
}

