/* Module: S */
.main-header {
  padding-top: pxToRem(22);
  height: pxToRem(114);
}

.header-content {
  margin-left: auto;
  margin-right: auto;
  max-width: pxToRem(1340);
}

.header__mobile-menu-toggle {
  display: none;
}

.header__mobile-menu {
  background-color: transparent;
  display: block;
  height: auto;
  position: static;
}

.header__mobile-wrapper {
  background-image: none;
}

.header-links {
  font-family: $font-open-sans;
  position: absolute;
  right: pxToRem(48);
  top: pxToRem(0);
  z-index: 2;
}

.header-links__item {
  display: inline-block;
  padding-left: pxToRem(27);
  background-color: transparent;
}

.header-links__item::after {
  display: none;
}

.header-links__link {
  display: inline;
  font-size: pxToRem(14);
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  color: $greyish-brown-two;
}

.main-header--home .header-links__link {
  color: $white;
}

.header-links__link::after {
  background-color: $lipstick;
  opacity: 0;
  content: '';
  display: block;
  width: 100%;
  height: pxToRem(3);
  position: relative;
  z-index: 2;
  transition: 0.2s opacity ease-in;
}

.header-links__item--active .header-links__link,
.header-links__link:hover {
  &::after {
    opacity: 1;
  }
}

.main-header--mobile-menu-active + main#main::before {
  display: none;
}
