/* Module: S */
.header-text {
    background-color: $white;
    margin-left: calc((100vw - 100%) / -2);
    width: 100vw;
}

.header-text__header {
    color: $light-navy;
    font-size: pxToRem(26);
    line-height: pxToRem(34);
    font-family: $font-open-sans;
    font-weight: normal;
    padding: pxToRem(30) pxToRem(20) 0;
}

.header-text__text {
    width: 100vw;
    padding: pxToRem(30) pxToRem(20);
    p {
        color: $black-two;
        font-size: pxToRem(14);
        line-height: pxToRem(28);
        font-family: $font-open-sans;
        margin: pxToRem(20) 0;
    }
}
