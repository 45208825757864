/* Module: S */
.hero::before {
  height: pxToRem(277);
}

.hero::after {
  height: pxToRem(277);
}

.hero__image {
  height: pxToRem(532);
}

.hero__text {
  bottom: pxToRem(50);
  font-size: pxToRem(50);
  left: calc((#{pxToRem($width-desktop)} - 100%) / -2);
  line-height: pxToRem(90);
  padding-left: 0;
  padding-right: 0;
  width: pxToRem($width-desktop);
  z-index: 1;
}

.hero__scroll {
  border: pxToRem(3) solid $white;
  border-radius: pxToRem(11);
  bottom: pxToRem(59);
  content: '';
  display: block;
  height: pxToRem(36);
  position: absolute;
  right: calc((100vw - #{pxToRem($width-desktop)}) / 2);
  width: pxToRem(22);
  z-index: 1;
}

.hero--text-middle .hero__text {
  font-size: pxToRem(70);
  line-height: pxToRem(90);
  font-weight: normal;
}

.hero__text--narrow {
  max-width: 37%;
}

.hero__scroll::before {
  animation: 1.6s scrollDown infinite ease;
  background-color: $white;
  border-radius: pxToRem(2);
  bottom: pxToRem(21);
  content: '';
  display: block;
  height: pxToRem(7);
  position: absolute;
  right: pxToRem(7);
  width: pxToRem(2);
  z-index: 1;
}

.hero__text.hero__text--project {
  font-size: pxToRem(50);
}

.page--service-details .hero__text {
  padding-right: pxToRem(55);
  line-height: 1.5;
}