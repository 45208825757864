@keyframes scrollDown {
    from {
      opacity: 0;
      transform: translateY(0);
    }
  
    to {
      opacity: 1;
      transform: translateY(#{pxToRem(5)});
    }
  }
  
$font-neustadt: 'Neustadt';
$font-open-sans: 'Open Sans';

@font-face {
    font-family: $font-neustadt;
    src: url("{{file_dest}}/neustadt-light-webfont.woff2") format("woff2"),url("{{file_dest}}/neustadt-light-webfont.woff") format("woff");
    font-weight: 200;
    font-style: normal
}

@font-face {
    font-family: $font-neustadt;
    src: url("{{file_dest}}/neustadt-regular-webfont.woff2") format("woff2"),url("{{file_dest}}/neustadt-regular-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: $font-neustadt;
    src: url("{{file_dest}}/neustadt-semibold-webfont.woff2") format("woff2"),url("{{file_dest}}/neustadt-semibold-webfont.woff") format("woff");
    font-weight: 600;
    font-style: normal
}

