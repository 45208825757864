/* Module: S */
.slick-disabled {
  display: none !important; 
  pointer-events:none;
}

.card-grid {
  padding: pxToRem(35) pxToRem(40) pxToRem(30);
  clear: both;
}

.card-grid--search-results,
.card-grid.card-grid--home,
.card-grid.card-grid--aside,
.card-grid.card-grid--services-projects {
  padding: 0;
}

.card-grid__title {
  font-family: $font-open-sans;
  font-weight: normal;
  font-size: pxToRem(20);
  line-height: 1.46;
  margin: 0;
}


.card-grid--gray {
  background-color: $very-light-pink-three;
  margin-left: pxToRem(-$padding-mobile);
  margin-right: pxToRem(-$padding-mobile);
  padding: pxToRem(30) pxToRem(20) pxToRem(20);
}

.card-grid__header {
  color: $black-two;
  font-size: pxToRem(20);
  font-weight: 300;
  margin: 0 0 pxToRem(15);
}

.card-grid__item--project {
  padding-top: pxToRem(50);
}

.card-grid__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.card-grid__item {
  background-color: $white;
  margin-bottom: pxToRem(40);
  width: 100%;
}

.card-grid__link {
  color: $black-two;
  text-decoration: none;
  width: 100%;
}
.card-grid--search-results {
  .card-grid__meta {
    padding-left: pxToRem(20);
  }
}

.card-grid__item--white-bg .card-grid__meta {
  padding-left: 0;
}

.card-grid--home .card-grid__meta {
  padding-left: 0;
}

.contact-offices .card-grid__meta {
  padding-left: 0;
}

.card-grid--slider .card-grid__meta {
  padding-left: 0;
}

.card-grid__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: pxToRem(300);
  padding: pxToRem(20) pxToRem(20) pxToRem(20) pxToRem(20);
  width: 100%;
}

.card-grid__type {
  text-transform: capitalize;
  color: $rouge;
  font-family: $font-neustadt;
  font-size: pxToRem(20);
}



.card-grid__item--project .card-grid__title {
  font-size: pxToRem(26);
}

.card-grid__more {
  display: block;
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  font-weight: 600;
  margin-top: pxToRem(20);
  text-transform: uppercase;
}

.card-grid__date {
  font-family: $font-neustadt;
  font-size: pxToRem(20);
  font-weight: normal;
  line-height: 1;
  color: $brownish-grey;
  margin-left: pxToRem(15);
  display: none;
}

.card-grid__subtitle {
  color: $brownish-grey;
  font-size: pxToRem(12);
  font-weight: 600;
  margin-bottom: pxToRem(6);
  margin-top: 0;
  text-transform: uppercase;
  position: relative
}

.card-grid__subtitle--extra-space {
  bottom: pxToRem(10);
  position: relative
}

.card-grid__show-more {
  color: $black-two;
  display: block;
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  font-weight: 600;
  line-height: 1.67;
  padding-right: pxToRem(10);
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: pxToRem(16);
}

.card-grid__show-more .icon-arrow-right-thin {
  height: pxToRem(18);
  left: pxToRem(12);
  position: relative;
  top: pxToRem(5);
  width: pxToRem(18);
}

.card-grid__description {
  font-size: pxToRem(14);
  line-height: 1.86;
}

.card-grid--services-projects {
  .card-grid__image {
    height: pxToRem(266);
    //object-fit: cover;
    background-size: cover;
    background-position: center center;
  }

  .card-grid__details {
    min-height: pxToRem(160);
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
    padding-bottom: pxToRem(20);
  }

  .card-grid__title {
    font-size: pxToRem(24);
  }
}

.card-grid--aside {
  .card-grid__list {
    padding-bottom: pxToRem(30);
  }

  .card-grid__title {
    color: $black-two;
    line-height: 1.55;
  }

  .card-grid__date {
    display: inline-block;
  }
}

.search-results__header {
  padding-bottom: pxToRem(20);
}

.search-results__heading {
  font-family: $font-neustadt;
  margin-bottom: pxToRem(15);
}

.search-results__info {
  font-size: pxToRem(14);
}

.card-grid__image {
  width: 100%;
}

.card-grid--search-results {
  .card-grid__item {
    position: relative;
  }

  .card-grid__item--white-bg {
    background: $white;
    //padding-left: pxToRem($padding-mobile);
    //padding-right: pxToRem($padding-mobile);
  }

  .card-grid__item::after {
    background-color: $white;
    bottom: 0;
    content: '';
    display: block;
    height: pxToRem(2);
    left: 0;
    position: absolute;
    width: 100%;
  }

  .card-grid__item--white-bg::after {
    display: none;
  }

  .card-grid__image {
    display: block;
    margin-top: pxToRem(20);
    object-fit: cover;
    background-size: cover;
    background-position: center center;
  }
  
  .card-grid__image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    min-height: pxToRem(266);
    display: block;
  }

  .card-grid__item--white-bg .card-grid__meta {
    padding-top: 0;
    margin-bottom: pxToRem(10);
  }

  .card-grid__details {
    min-height: 0;
  }

  .card-grid__link {
    flex-direction: column;
  }
}

.svg-icon.icon-link-external-nc {
  width: pxToRem(16);
  height: pxToRem(16);
  position: relative;
  top: 2px;
  left: 10px;
  fill: $lipstick;
  stroke: $lipstick;
}

.card-grid--slider {
  background-color: $very-light-pink-three;
  margin-left: pxToRem(-$padding-mobile);
  margin-right: pxToRem(-$padding-mobile);
  &.card-grid--service-details .card-grid__image {
    height: pxToRem(185);
    //object-fit: cover;
    background-size: cover;
    background-position: center center;
    width: 100%;
  }

  .card-grid__item {
    flex: 1;
  }

  .card-grid__details {
    height: pxToRem(376);
    min-height: auto;
  }

  &.card-grid--service-details .card-grid__details {
    height: pxToRem(191);
  }

  .slick-arrow {
    background-color: rgba(255,255,255,.7);
    height: pxToRem(376);
    margin: 0;
    padding: 0;
    position: absolute;
    text-indent: -999em;
    top: 0;
    width: pxToRem(40);
  }

  .slick-arrow:hover,
  .slick-arrow:focus {
    background-color: rgba(255,255,255,.7);
  }

  .slick-arrow::after {
    background-image: url({{file_dest}}/arrow-right-thin.svg);
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: pxToRem(16);
    left: calc(50% - #{pxToRem(12)});
    position: absolute;
    top: calc(50% - #{pxToRem(8)});
    width: pxToRem(23);
  }

  .slick-next {
    right: pxToRem(-40);
  }

  .slick-prev {
    left: pxToRem(-40);
  }

  .slick-prev::after {
    transform: rotate(.5turn);
  }
}