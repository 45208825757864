/* Module: S */
.services-navigation {
  display: block;
}

.services-navigation__list {
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  width: pxToRem($width-desktop);
}

.services-navigation__item {
  display: inline;
}