/* Module: S */
.image-slider {
  margin: pxToRem(40) pxToRem(-$padding-mobile) pxToRem(20);
  position: relative;
}

.image-slider::after {
  content: '';
  display: block;
  width: 100%;
  height: 53px;
  position: absolute;
  bottom: 53px;
  left: 0;
  background: $very-light-pink-four;
  z-index: 0;
}

.image-slider__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.image-slider__details {
  background-color: $very-light-pink-four;
  height: pxToRem(53);
  padding-left: pxToRem($padding-mobile);
  padding-right: pxToRem($padding-mobile);
  padding-top: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.image-slider__title {
  color: $brownish-grey;
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  line-height: 1.67;
  text-transform: uppercase;
}

.image-slider__item {
  padding-bottom: pxToRem(106);
  position: relative;
}

.image-slider__image {
  height: pxToRem(185);
  width: 100%;
  object-fit: cover;
}

.image-slider {
  .slick-arrow {
    background-color: transparent;
    bottom: pxToRem(53);
    height: pxToRem(53);
    margin: 0;
    padding: 0;
    position: absolute;
    text-indent: -999em;
    width: pxToRem(20);
    z-index: 1;
  }

  .slick-arrow:hover,
  .slick-arrow:focus {
    background-color: transparent;
  }

  .slick-arrow::after {
    background-image: url({{file_dest}}/arrow-right-thin.svg);
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: pxToRem(16);
    left: calc(50% - #{pxToRem(12)});
    position: absolute;
    top: calc(50% - #{pxToRem(8)});
    width: pxToRem(23);
  }

  .slick-next {
    right: pxToRem($padding-mobile);
  }

  .slick-prev {
    left: pxToRem($padding-mobile);
  }

  .slick-prev::after {
    transform: rotate(.5turn);
  }

  .slick-dots {
    bottom: pxToRem(56);
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: pxToRem(56);
    z-index: 1;
  }

  .slick-dots li {
    display: inline-block;
    padding-left: pxToRem(16);
  }

  .slick-dots button {
    background-color: $light-grey-blue;
    border: pxToRem(2) solid $very-light-pink-four;
    border-radius: pxToRem(5);
    height: pxToRem(10);
    padding: 0;
    text-indent: -999em;
    width: pxToRem(10);
  }

  .slick-dots button:hover,
  .slick-dots button:focus {
    background-color: $lipstick;
    border-color: $lipstick;
  }

  .slick-active button {
    background-color: $lipstick;
    border-color: $lipstick;
  }
}
