/* Module: S */
.home-cta {
  background-color: $light-navy;
  margin-left: calc((100vw - 100%) / -2);
  width: 100vw;
  margin-bottom: pxToRem(50);
}

.home-cta__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.home-cta__item {
  border-bottom: pxToRem(1) solid rgba(0,0,0,.2);
  padding: pxToRem(27) pxToRem($padding-mobile);
}

.home-cta__item:last-of-type {
  border-bottom: none;
}

.home-cta__link {
  color: $white;
  text-decoration: none;
}

.home-cta__title {
  font-size: pxToRem(32);
  font-weight: 300;
  margin-bottom: pxToRem(11);
  margin-top: 0;
}

.home-cta__subtitle {
  font-family: $font-open-sans;
  font-size: pxToRem(18);
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}

.home-cta__subtitle .icon-arrow-right-thin {
  height: pxToRem(20);
  left: pxToRem(10);
  opacity: 0;
  position: relative;
  top: pxToRem(5);
  transition: .2s all ease-in;
  width: pxToRem(20);
}
