/* Module: S */
.video__container {

}

div + .video__container {
    margin: pxToRem(32) 0;
}

.video__container--right {
    display: flex;
}

.video__container--left {
    display: flex;
    flex-direction: row-reverse;
}

.video__header {
    font-size: pxToRem(44);
    line-height: pxToRem(60);
}
.video__container--left
.video__thumbnail-wrapper,
.video__container--right 
.video__thumbnail-wrapper{
    width: 50%;
}

.video__text {
    padding: pxToRem(30) pxToRem(50);
    width: 50%;
}

.video__thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video__popup { 
    top: -60px;
}

main > .container .video__container--right button.video__open {
    margin: auto;
    position: absolute;
    top: 0; 
    bottom: 0; 
    left: initial;
    right: -25px;
}

main > .container .video__container--left button.video__open {
    margin: auto;
    position: absolute;
    top: 0; 
    bottom: 0; 
    right: initial;
    left: -25px;
}


main > .container button.video__open {
    margin: auto;
    position: absolute;
    top: 0; 
    bottom: 0; 
    left: 0;
    right: 0;
}