/* Module: S */
.stats-bar {
  display: none;
  font-family: $font-neustadt;
}

.stats-bar--full-width {
  width: 100vw;
  margin-left: pxToRem(-$padding-mobile);
  margin-right: pxToRem(-$padding-mobile);
  display: block;
  background-color: $very-light-pink;
  padding-top: pxToRem(30);
  padding-bottom: pxToRem(40);
}

.stats-bar--full-width {
  .stats-bar__item-text {
    color: $brownish-grey;
    margin-top: 0;
    margin-bottom: pxToRem(20);
  }
}

.stats-bar__list {

  list-style-type: none;
  margin: 0;
  padding: 0;
}

.stats-bar__item {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: pxToRem(60);
  padding-right: pxToRem(60);
  text-align: center;
  width: 100%;
}

.stats-bar__item-value {
  color: $carmine;
  font-size: pxToRem(52);
  font-weight: 300;

  @supports (mix-blend-mode: lighten) {
    -webkit-background-clip: text;
            background-clip: text;
    background-image: linear-gradient(to right, $watermelon, $carmine);
    -webkit-text-fill-color: transparent;
  }
}

.stats-bar__item-text {
  color: $brownish-grey;
  font-size: pxToRem(18);
  font-weight: 600;
  line-height: 1.11;
  margin-top: pxToRem(18);
}
