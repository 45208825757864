/* Module: S */
.card-grid__header {
  font-size: pxToRem(26);
}

.card-grid__list {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  justify-content: flex-start;
}

.card-grid__item {
  transition: .2s background-color ease-in;
}

.card-grid__item:hover {
  background-color: $rouge;
}

.card-grid__link {
  display: flex;
  justify-content: space-between;
}

.card-grid__item:hover .card-grid__link {
  color: $white;
}

.card-grid--3-col .card-grid__item {
  width: pxToRem(380);
  margin-right: 20px;
}

.card-grid--3-col .card-grid__item:nth-child(3n) {
  margin-right: 0;
}

.card-grid__item:hover .card-grid__type {
  color: $white;
}

.card-grid__date {
  color: $brownish-grey;
  display: inline-block;
  font-family: $font-neustadt;
  padding-left: pxToRem(14);
}

.card-grid__title {
  font-size: pxToRem(22);
}

.card-grid.card-grid--home .card-grid__title {
  font-size: pxToRem(24);
}

.card-grid__item--project .card-grid__title {
  font-size: pxToRem(30) !important;
}

.card-grid__item:hover .card-grid__subtitle {
  color: $white;
}

.card-grid__item:hover .card-grid__date {
  color: $white;
}

.card-grid__item:hover .link--external::after {
  content: url('{{file_dest}}/link-external-white.svg');
}

.card-grid__item:hover .icon-link-external-nc {
  fill: $white;
  stroke: $white;
}

.card-grid--gray {
  margin-left: calc((100vw - 100%) / -2);
  padding: pxToRem(30) pxToRem(40) pxToRem(20);
  width: 100vw;

  .card-grid__header {
    margin-left: auto;
    margin-right: auto;
    width: pxToRem($width-desktop);
  }

  .card-grid__list {
    margin-left: auto;
    margin-right: auto;
    width: pxToRem($width-desktop);
  }
}

.card-grid--slider {
  margin-left: calc((100vw - 100%) / -2);
  width: 100vw;

  .card-grid__header {
    margin-left: auto;
    margin-right: auto;
    width: pxToRem($width-desktop);
  }

  .card-grid__list {
    margin-left: auto;
    margin-right: auto;
    width: pxToRem($width-desktop + 20);
  }

  .card-grid__item {
    margin-left: pxToRem(10);
    margin-right: pxToRem(10);
    max-width: pxToRem(380);
  }

  .card-grid__link {
    display: block;
  }

  &.card-grid--service-details .card-grid__image {
    height: pxToRem(200);
    width: 100%;
  }

  &.card-grid--service-details .card-grid__details {
    min-height: pxToRem(176);
    height: auto;
  }

  .slick-arrow {
    width: pxToRem(110);
  }

  .slick-next {
    right: pxToRem(-120);
  }

  .slick-prev {
    left: pxToRem(-120);
  }
}

.card-grid--services-projects {
  .card-grid__link {
    flex-wrap: wrap;
  }
}

.card-grid__show-more { 
  padding-bottom: pxToRem(32);
}

.card-grid--no-hover .card-grid__item:hover {
  //background-color: transparent;

  .card-grid__link,
  .card-grid__description {
    color: $white;
  }

  .card-grid__more {
    color: $white;
  }
}

.card-grid--aside {
  .card-grid__title {
    font-size: pxToRem(22);
  }

  .card-grid__details {
    min-height: pxToRem(360);
  }
}

.search-results__heading {
  font-size: pxToRem(50);
}

.search-results__info {
  font-size: pxToRem(16);
  margin-bottom: pxToRem(10);
}

.card-grid--search-results {
  display: flex;
  margin-left: pxToRem(-10);
  margin-right: pxToRem(-10);
  padding: 0;

  .card-grid__item {
    margin-left: pxToRem(10);
    margin-right: pxToRem(10);
    width: pxToRem(380);
  }

  .card-grid__item:hover {
    //background-color: transparent;

    .card-grid__type {
      color: $white;
    }

    .card-grid__date {
      color: $white;
    }

    .card-grid__link,
    .card-grid__description,
    .card-grid__more {
      color: $white;
    }
  }

  .card-grid__item--white-bg:hover {
    background-color: $rouge;

    .card-grid__date,
    .card-grid__type,
    .card-grid__link,
    .card-grid__description,
    .card-grid__more {
      color: $white;
    }
  }

  .card-grid__image img {
    height: pxToRem(266);
  }

  .card-grid__meta {
    display: block;
    margin-top: pxToRem(20);
    min-height: pxToRem(22);
  }

  .card-grid__item--white-bg .card-grid__meta {
    margin-top: pxToRem(0);
  }

  .card-grid__link {
    height: 100%;
  }

  .card-grid__details {
    height: 100%;
  }
}
