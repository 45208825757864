/* Module: S */


.header-nav ul,
.header-nav li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header-nav a {
  text-decoration: none;
}

.header-nav-l1__list {
  position: relative;
  transition: transform .2s ease-in;
}

.header-nav-l1__list--l2-active {
  transform: translateX(-100%);
  height: 0;
}

.header-nav-l1__list--l3-active {
  transform: translateX(-200%);
  height: 0;
}

.header-nav-l1__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header-nav-l1__item::after {
  background-color: $dark-indigo;
  content: '';
  display: block;
  height: pxToRem(2);
  opacity: .5;
  width: 100%;
}

.header-nav-l1__link {
  align-items: center;
  color: $white;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: pxToRem(20);
  font-weight: 600;
  height: pxToRem(76);
  padding-left: pxToRem(20);
  //padding-right: pxToRem(20);
  text-transform: uppercase;
}

.header-nav-l2__toggle {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: pxToRem(76);
  justify-content: center;
  padding: 0;
  width: pxToRem(40);
}

.header-nav-l2__toggle .icon-dash-right {
  height: pxToRem(11);
  width: pxToRem(6);
  fill: $white;
  stroke: $white;
}

.header-nav-l1__item.active {
  background-color: $dark-indigo;
}

.header-nav-l1__item.active .header-nav-l2__toggle .icon-dash-right {
  fill: $lipstick;
  stroke: $lipstick;
}

.header-nav-l2__list {
  background-color: $dark-indigo;
  left: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.header-nav-l2__item {
  align-items: center;
  display: flex;
  height: pxToRem(54);
  justify-content: space-between;
}

.header-nav-l2__link {
  color: $white-three;
  font-family: $font-open-sans;
  font-size: pxToRem(16);
  padding-left: pxToRem(20);
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

.header-nav-l3__list {
  background-color: $dark-indigo;
  left: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.header-nav-l3__item {
  align-items: center;
  display: flex;
  height: pxToRem(54);
  justify-content: space-between;
}

.header-nav-l3__link {
  color: $very-light-pink-two;
  font-size: pxToRem(18);
  font-weight: 600;
  padding-left: pxToRem(20);
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

.header-nav__go-back {
  align-items: center;
  background-image: linear-gradient(to top, $light-navy-two, $light-navy);
  border: none;
  display: flex;
  height: pxToRem(68);
  justify-content: start;
  padding-left: pxToRem(17);
  width: 100%;
}

.header-nav__go-back .icon-arrow-right {
  height: pxToRem(14);
  transform: rotate(.5turn);
  width: pxToRem(19);
}

.header-nav__go-back span {
  color: $white-three;
  font-family: $font-open-sans;
  font-size: pxToRem(16);
  font-weight: 600;
  padding-left: pxToRem(15);
}

.header-nav-l3__toggle {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: pxToRem(54);
  justify-content: center;
  padding: 0;
  width: pxToRem(40);
}


.header-nav-l3__toggle .icon-arrow-right {
  height: pxToRem(14);
  width: pxToRem(19);
}

.header-nav-l2__item.active .header-nav-l3__toggle .icon-arrow-right {
  fill: $lipstick;
  stroke: $lipstick;
}