/* Module: S */
.image-slider {
  clear: both;
  margin-left: 0;
  margin-right: 0;
}

.image-slider::after {
  bottom: 0;
  height: pxToRem(59);
}

.image-slider__list {
}

.image-slider__item {
  padding-bottom: pxToRem(56);
}

.image-slider__image {
  height: 100%;
}

.image-slider .slick-list {
  z-index: 1;
}

.image-slider__details {
  height: pxToRem(59);
  padding-top: 0;
  padding-left: pxToRem(63);
  z-index: 1;
}

.image-slider__title {
  margin: 0;
  line-height: pxToRem(59);
}

.image-slider {
  .slick-arrow {
    bottom: pxToRem(3);
    z-index: 2;
  }
  
  .slick-dots {
    bottom: pxToRem(3);
  }
}