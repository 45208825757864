/* Module: S */
figcaption {
    background-color: white;
    padding: pxToRem(20);
    font-family: $font-open-sans;
    font-size: pxToRem(12);
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: normal;
    color: $brownish-grey;
    text-transform: uppercase;
}

figure img {
    display: inherit;
    width: 100%;
}

.news-event__type {
    color: $rouge;
    font-size: pxToRem(20);
    font-family: $font-neustadt;
}

.news-event__date {
    font-family: $font-neustadt;
    font-size: pxToRem(20);
    font-weight: normal;
    line-height: 1;
    color: $brownish-grey;
    margin-left: pxToRem(15);
    display: inline-block;
}

p.strong {
    font-weight: bold;
}