/* Module: S */
.header-text {
    display: flex;
    margin: pxToRem(50) 0 pxToRem(35);
    width: 100%;
}

.header-text__text {
    padding: pxToRem(30) pxToRem(50) pxToRem(30) pxToRem(30);
    width: 50%;
    p {
        font-size: pxToRem(16);
        line-height: pxToRem(32);
        margin: pxToRem(20) 0;
    }
    p:first-of-type {
        margin-top: 0;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}

.header-text__header {
    font-size: pxToRem(30);
    line-height: pxToRem(40);
    margin-top: pxToRem(10);
    width: 50%;
    padding: pxToRem(15) 0;
}
