/* Module: S */
.breadcrumbs__item {
  display: block;
}

.breadcrumbs__item::after {
  transform: rotate(0);
  margin-right: 0;
  margin-left: pxToRem(8);
  margin-right: pxToRem(8);
  order: 1;
}

.breadcrumbs__item:last-of-type::after {
  visibility: hidden;
}