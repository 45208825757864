/* Module: S */
.search-filters {
  background-color: $light-navy;
  color: $white;
  padding: pxToRem(0) pxToRem($padding-mobile);
  font-family: $font-open-sans;
}

.search-filters__container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.search-filters__filter-input-wrapper {
  display: flex;
  border-bottom: pxToRem(2) solid rgba(179,182,184,0.5);
}

.search-filters__input {
  font-size: pxToRem(20);
  line-height: 1.46;
  height: pxToRem(46);
  background-color: transparent;
  color: $white;
  border: none;
  width: calc(100% - #{pxToRem(46)});
}

.search-filters__search-button {
  border: 0;
  background-color: transparent;
  height: pxToRem(46);
  width: pxToRem(46);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
}

.search-filters__search-button .svg-icon.icon-search {
  fill: $white;
  width: pxToRem(21);
  height: pxToRem(22);
}

.search-filters__label {
  display: inline-block;
  font-size: pxToRem(16);
  font-weight: 300;
  margin-bottom: pxToRem(10);
}

.search-filters__filter {
  width: 100%;
  margin-top: pxToRem(14);
  margin-bottom: pxToRem(14);
  .selectize-control {
    border-bottom: pxToRem(1) solid rgba(0,0,0,.2);
    z-index: 1;
    height: pxToRem(40);
  }
  .selectize-input.full {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: $white;
    font-size: pxToRem(16);
    font-weight: 600;
    padding-left: 0;
  }

  .selectize.single .selectize-input.full.input-active {
    background-color: transparent;
  }

  .selectize-control.single .selectize-input::after {
    background-image: url('{{file_dest}}/arrow-bottom-thin.svg');
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
    height: pxToRem(10);
    right: 0;
    transform: rotate(.25turn);
    width: pxToRem(18);
  }

  .selectize-control.single .selectize-input.dropdown-active::after {
    background-image: url('{{file_dest}}/arrow-right-thin.svg');
    transform: rotate(-.25turn);
  }

  .selectize-dropdown-content {
    max-height: none;
  }

  .selectize-dropdown {
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    border-radius: 0;
    font-size: pxToRem(16);
  }

  .selectize-dropdown .option {
    padding: pxToRem(14) pxToRem(13) pxToRem(12);
    color: $dark-indigo-four;
    border-bottom: pxToRem(1) solid $very-light-blue;
  }

  .option:hover {
    background-color: $very-light-blue;
  }

  .option.selected {
    background-color: $very-light-blue;
  }
}

.page--search-results .search-filters__filter {
  text-transform: capitalize;
}