/* Module: S */
.contact-map {
    background-color: $dark-indigo;
    color: $white;
    margin-left: pxToRem(-$padding-mobile);
    margin-right: pxToRem(-$padding-mobile);
    text-align: center;
}

.contact-map__heading {
    padding-left: pxToRem($padding-mobile);
    padding-right: pxToRem($padding-mobile);
    font-weight: normal;
    font-size: pxToRem(24);
    padding-top: pxToRem(28);
    text-align: left;
}

.contact-map__map-wrapper {
    position: relative;
    width: pxToRem(270);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: pxToRem(20);
}

.contact-map__main-map {
    width: 100%;
    height: auto;
}

.contact-map__main-map path {
    fill: $light-navy;
    transition: .2s fill ease-in;
}

.contact-map__main-map path.mapactive,
.contact-map__main-map path:hover {
    fill: $dark-indigo-five;
}

.contact-map__region {
    position: absolute;
    text-transform: uppercase;
    font-size: pxToRem(10);
    font-weight: bold;
}

.contact-map__region span {
    display: block;
    line-height: 1.67;
}

.contact-map__region .icon-place-inactive {
    width: pxToRem(15);
    height: pxToRem(20);
    fill: #B3B6B8;
}

.contact-map__region--active .icon-place-inactive {
    display: none;
}

.contact-map__region .icon-place-active {
    width: pxToRem(18);
    height: pxToRem(24);
    margin-top: pxToRem(-4);
    display: none;
    fill: $lipstick;
}

.contact-map__region--active .icon-place-active {
    display: inline-block;
}

.contact-map__region-riverina {
    top: pxToRem(92);
    left: pxToRem(30);
}

.contact-map__region-northcoast {
    top: pxToRem(10);
    right: pxToRem(-18);
}

.contact-map__region-hunter {
    top: pxToRem(54);
    right: pxToRem(0);
}

.contact-map__region-southcoast {
    top: pxToRem(170);
    right: pxToRem(53);
}

.contact-map__region-sydney {
    top: pxToRem(120);
    right: pxToRem(36);
}

.contact-person__item {
    display: none;
}

.contact-person__item--active {
    display: block;
}

.contact-person__item--sydney {
    border-bottom: pxToRem(2) solid $rouge;
}

.contact-person .inside-widget {
    margin-left: 0;
    margin-right: 0;
    text-align: left;
}

.contact-person .inside-widget__name {
    font-weight: bold;
}

.contact-offices {
    margin-left: pxToRem(-$padding-mobile);
    margin-right: pxToRem(-$padding-mobile);
    background-color: $very-light-pink-three;
    padding-bottom: 1px;
}

.contact-offices--hidden {
    display: none;
}

.contact-offices__heading {
    height: pxToRem(133);
    background-color: $dark-indigo;
    color: $white;
    font-size: pxToRem(20);
    font-weight: normal;
    padding: pxToRem(29) pxToRem($padding-mobile) 0;
    font-family: $font-neustadt;
    margin: 0;
}

.contact-offices__card-grid-wrapper {
    margin-top: pxToRem(-53);
    width: 100%;
}

.contact-offices__card-grid-wrapper .card-grid {
    padding: 0 pxToRem($padding-mobile);
}

.contact-offices__office {
    display: none;
}

.contact-offices__office--active {
    display: block;
}