/* Module: S */
.home-search__form .twitter-typeahead {
    width: 100%;
    flex: 1;
}

.twitter-typeahead .home-search__input {
	height: 100%;
}

.search-suggest-result-large__title {
	font-size: pxToRem(18);
	line-height: 1.33;
	font-weight: 600;
}

.search-suggest-result-large__summary {
	display: block;
}


.search-suggest-result__container,
.search-suggest-result-large__container {
	padding: pxToRem(20) pxToRem(20) 0 pxToRem(20);
	margin: 0 0 pxToRem(20) 0;
}