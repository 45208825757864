/* Module: S */
.breadcrumbs {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    align-items: center;
    color: $purple-grey;
    font-size: pxToRem(14);
    line-height: pxToRem(20);
    font-family: $font-open-sans;
}

.breadcrumbs__item:nth-last-child(2) {
    display: flex;
    align-items: center;
}

.breadcrumbs__item {
    display: none;
}

.breadcrumbs__item::after {
    content: url('{{file_dest}}/chevron-right.svg');
    width: pxToRem(8);
    height: pxToRem(20);
    margin-right: pxToRem(8);
    display: inline-block;
    order: -1;
    transform: rotate(.5turn);
}

.breadcrumbs__link {
    color: $black-two;
    font-size: pxToRem(14);
    line-height: pxToRem(20);
    font-family: $font-open-sans;
    text-decoration: none;
}