/* Module: S */
.links-box {
    background-color: $light-navy;
    padding: pxToRem(20) pxToRem($padding-mobile);
    color: $white;
    width: 100vw;
    margin-left: calc((100vw - 100%) / -2);
}

.links-box__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style-type: none;
    padding-left: 0;
}

.links-box__list-item {
    margin: pxToRem(5) 0;
}

.links-box__link {
    font-family: $font-neustadt;
    font-size: pxToRem(16);
    font-weight: 600;
    line-height: 1.25;
    color: $very-light-pink;
    text-decoration: none;
}

.links-box__link.links-box__link--active {
    border-bottom: 1px solid $very-light-pink;
}

.text-on-image-tab {
    display: none;
}

.text-on-image-tab--active {
    display: block;
}