/* Module: S */
.inside-widget {
  margin-left: pxToRem(-$padding-mobile);
  margin-right: pxToRem(-$padding-mobile);
  background-color: $light-navy;
  color: $white;
  padding: pxToRem(23) pxToRem($padding-mobile) pxToRem(33);
  font-family: $font-open-sans;

}

.inside-widget.inside-widget--aside {
  margin-bottom: pxToRem(30);
}

.inside-widget__person-wrapper {
  display: flex;
  align-items: center;
  padding-top: pxToRem(16);
  font-size: pxToRem(14)
}

.inside-widget__header {
  margin: 0;
  font-weight: 300;
  font-size: pxToRem(24);
}

.inside-widget__person-image {
  border: solid pxToRem(4) $white;
  border-radius: pxToRem(50);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
  height: pxToRem(63);
  width: pxToRem(63);
  margin-right: pxToRem(20);
}

.inside-widget__person-details {
  line-height: 1.43;
}

.inside-widget__person-wrapper span {
  display: block;
}

.inside-widget__contact-list {
  margin: 0;
  padding: pxToRem(20) 0 0;
  list-style-type: none;
  font-size: pxToRem(14);
}

.inside-widget__contact-item {
  display: flex;
  align-items: center;
  padding-bottom: pxToRem(5);
}

.inside-widget__contact-item:last-of-type {
  padding-bottom: 0;
}

.inside-widget__contact-item .icon-contact-mail {
  width: pxToRem(20);
  height: pxToRem(15);
  fill: $white;
  flex-shrink: 0;
}

.inside-widget__contact-item .icon-contact-linkedin {
  width: pxToRem(19);
  height: pxToRem(15);
  fill: $white;
}

.inside-widget__contact-link {
  font-weight: 600;
  text-decoration: none;
  color: $white;
  padding-left: pxToRem(15);
  word-break: break-word;
}

.inside-widget__contact-details {
  font-size: pxToRem(14);
  line-height: 1.43;
  min-height: pxToRem(100);
}

// .inside-widget__contact-footer {

// }

.inside-widget__contact-inquiry {
  font-size: pxToRem(12);
  font-weight: 600;
  font-family: $font-open-sans;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
}

.inside-widget__contact-inquiry .icon-arrow-thin-right {
  width: pxToRem(20);
  height: pxToRem(15);
  position: relative;
  top: pxToRem(3);
  left: pxToRem(5);
  transition: .2s ease-in translate;
}

.inside-widget__details {
  margin-bottom: pxToRem(30);
}

.inside-widget__service-link {
  color: $white;
  text-decoration: none;
  display: block;
  font-size: pxToRem(14);
  font-weight: 600;
}

.inside-widget__details--awards {
  p { 
    display: flex;
    align-items: flex-start;
    font-size: pxToRem(14);
  }
.svg-icon {
  width: pxToRem(18);
  height: pxToRem(26);
  margin-right: pxToRem(8);
  flex-shrink: 0;
  position: relative;
  top: pxToRem(5);
}
}

.inside-widget__phone a {
  color: $white;
  text-decoration: none;
}

// SUPPORT-401607, Default Contact Information Styles
.inside-widget__default-header{
  font-size: 28px;
  margin-bottom: 10px;
}

.default-contact-list{
  padding-top: 0;
}

.default-details{
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.default-contact-item{
  min-height: 40px;

  img{
    max-width: 25px;
    margin-right: 10px;
  }
  
}

.default-contact-item-desc{
  min-height: 40px;
  margin-top: 10px;
  font-size: 16px;
}

.inside-widget__phone-icon{
  margin-right: 4px; 
}

.default-phone-no{
  font-weight: 600;
  font-size: 16px;
}

.inside-widget__mail-icon{
  margin-right: 5px;  
  position: relative;
  top: 2px;
}

.default__mail-link{
  padding: 0;  
  font-weight: 600;
  font-size: 14px;
}

// EXTERNAL FORM PADDING
.contact-us__form{
  padding-top: 50px;
}