/* Module: S */
.contact-us__header {
  color: $light-navy;
  line-height: 1.33;
}

.contact-us__header span {
  display: block;
  font-weight: normal;
  font-size: pxToRem(24);
}

.contact-us__form-wrapper label {
  font-size: pxToRem(12);
  text-transform: uppercase;
  display: block;
  color: $dark-indigo;
  font-weight: bold;
}

.container .contact-us__form-wrapper input[type=text],
.container .contact-us__form-wrapper textarea {
  border: none;
  background-color: rgba(239,238,238,.5);
}

.container .contact-us__form-wrapper input[type=text] {
  min-height: pxToRem(50);
}

.container .contact-us__form-wrapper textarea {
  min-height: pxToRem(150);
}

.contact-summary {
  text-align: right;
}

.container .contact_submit {
  background-color: transparent;
  line-height: 1.67;
  padding: 0;
  margin: pxToRem(10) 0 0;
}

.container .contact_submit:hover,
.container .contact_submit:focus {
  background-color: transparent;
}

.contact_submit span {
  color: $black-two;
  font-size: pxToRem(12);
  opacity: 0.5;
}

.contact_submit .icon-arrow-right-thin {
  width: pxToRem(20);
  height: pxToRem(12);
  position: relative;
  top: pxToRem(2);
}