/* Module: S */
main > .container,
form > .container {
    font-family: $font-open-sans;
    padding-left: pxToRem($padding-mobile);
    padding-right: pxToRem($padding-mobile);
    margin-top: pxToRem(30);
    th {
        color: $black;
        background-color: $very-light-pink-three;
        border-color: $very-light-pink-three;
        padding: pxToRem(5);
    }
    td {
        border-color: $very-light-pink-three;
        padding: pxToRem(5);
    }
}

main.main--home > .container {
    margin-top: 0;
}

main.main--home {
    background-color: $very-light-pink-three;
    padding-bottom: pxToRem(0);
}

main.main--grey-bg {
    background-color: $very-light-pink-three;
}

.page--service-details main > .container {
    font-size: pxToRem(14);
    font-family: $font-open-sans;
    line-height: 1.86;
    color: $black-two;
}

.page--search-results main {
    background-color: $very-light-pink-three;
}

.page--search-results .main > .container > .inside-top-bar {
    display: none;
}

.page--search-results.page--news-events-listing .main > .container > .inside-top-bar {
    display: flex;
}


.page--search-results .main > .container {
    margin-top: 0;
}