/* Module: S */
.two-col__container {
    display: flex;
    max-width: pxToRem($width-desktop);
}

.two-col__content {
    width: 100%;
    max-width: pxToRem(735);
}

.two-col__aside {
    width: pxToRem(380);
    margin-left: pxToRem(65);
}