/* Module: S */
.home-cta {
  margin: pxToRem(-67) auto pxToRem(70);
  width: pxToRem($width-desktop);
  height: pxToRem(135);
  position: relative;
  z-index: 1;
}

.home-cta__list {
  display: flex;
}

.home-cta__item {
  display: block;
  padding: 0;
  border-bottom: none;
  border-left: pxToRem(1) solid rgba(0,0,0,.2);
  width: 33.3%;
}

.home-cta__item:last-of-type {
  border-right: none;
}

.home-cta__link {
  display: block;
  padding: pxToRem(27) pxToRem(32);
}

.home-cta__link:hover {
  background-image: linear-gradient(271deg, $light-navy, $dark-indigo);
}





.home-cta__link:hover .icon-arrow-right-thin {
  opacity: 1;
  transform: translateX(pxToRem(6));
}
