/* Module: S */
.footer-newsletter {
  order: -1;
  width: 100%;
  background-color: $light-navy;
  color: $white;
  padding: pxToRem(5) pxToRem(20) pxToRem(35) pxToRem(20);
}

.footer-newsletter__form {
  border-bottom: pxToRem(2) solid $dark-indigo;
}

.footer-newsletter__label {
  font-size: pxToRem(26);
  font-weight: 600;
}

.footer-newsletter__description {
  font-size: pxToRem(14);
  line-height: 1.57;
  font-family: $font-open-sans;
}

.footer-newsletter__sign-up {
  display: flex;
  justify-content: space-between;
}

.footer-newsletter__email {
  color: $white;
  width: 100%;
  border: none;
  background-color: transparent;
}

.footer-newsletter__email::placeholder {
  color: $white;
}

.footer-newsletter__email::-ms-input-placeholder {
  color: $white;
}

.footer-newsletter__submit {
  border: none;
  background-color: transparent;
  width: pxToRem(30);
  height: pxToRem(45);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
}

.footer-newsletter__submit .icon-arrow-right {
  width: pxToRem(21);
  height: pxToRem(20);
}