/* Module: S */
.rich-slider {
    width: pxToRem($width-desktop);
    margin-left: 0;
    clear: both;
}

.rich-slider__list {
}

.rich-slider__item {
}

.rich-slider__item-details {
}

.rich-slider__item-thumbnail {
}

.rich-slider__item-title {
    font-size: pxToRem(28);
    width: pxToRem(835);
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.rich-slider__item-description {
    font-size: pxToRem(16);
    width: pxToRem(835);
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.rich-slider__item-author {
    width: pxToRem(835);
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.rich-slider__item-more {
}

.rich-slider__item-more-text {
}

.svg-icon.icon-arrow-right-thin {
}