/* Module: S */
.hero {
  position: relative;
  z-index: 0;
}

.hero::after {
  background-image: linear-gradient(to top, $black, $black-0);
  bottom: 0;
  content: '';
  display: block;
  height: pxToRem(174);
  left: 0;
  opacity: .6;
  position: absolute;
  width: 100%;
}

.hero::before {
  background-image: linear-gradient(to top, $black-0, $black);
  content: '';
  display: block;
  height: pxToRem(174);
  left: 0;
  opacity: .6;
  position: absolute;
  top: 0;
  width: 100%;
}

.hero__image {
  display: block;
  height: pxToRem(332);
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  width: 100%;
}

.hero__text {
  top: 0;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: pxToRem(30);
  left: 0;
  line-height: pxToRem(38);
  margin: 0;
  padding: pxToRem(33) pxToRem($padding-mobile);
  position: absolute;
  z-index: 1;
  height: 100%;
  text-shadow: 0px 0px 9px black;
}

.hero--text-middle .hero__text {
  font-size: pxToRem(42);
  line-height: pxToRem(52);
  justify-content: center;
}

.hero__location {
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  font-weight: bold;
  line-height: 1.67;
  color: $white;
  text-transform: uppercase;
  padding-bottom: pxToRem(10);
}

.icon-location {
  width: 15px;
  height: 20px;
  position: relative;
  top: 4px;
  margin-right: 6px;
}

.hero__text.hero__text--project {
  font-size: pxToRem(30);
  font-weight: normal;
  line-height: 2.375rem;
}