/* Module: S */
.contact-map {
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
}

.contact-map__container {
    width: pxToRem($width-desktop);
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.contact-map__main-map {
    width: 660px;
    height: 575.76px;
}

.contact-map__heading {
    font-size: pxToRem(30);
    padding-top: pxToRem(55);
    padding-left: 0;
}
.contact-map__map-wrapper {
    width: pxToRem(660);
    margin-left: pxToRem(60);
}

.contact-person {
    position: absolute;
    right: 0;
    top: pxToRem(138);
}

.contact-person__item--sydney {
    border-bottom: 0;
}

.contact-map__region {
    font-size: pxToRem(12);
}

.contact-map__region-riverina {
    top: pxToRem(211);
    left: pxToRem(190);
}

.contact-map__region-northcoast {
    top: pxToRem(70);
    right: pxToRem(0);
}

.contact-map__region-hunter {
    top: pxToRem(110);
    right: pxToRem(80);
}

.contact-map__region-southcoast {
    top: pxToRem(390);
    right: pxToRem(170);
}

.contact-map__region-sydney {
    top: pxToRem(311);
    right: pxToRem(124);
}

.contact-offices {
    margin-left: calc((100vw - 100%) / -2);
    margin-right: auto;
    width: 100vw;
    background-color: transparent;
    margin-top: pxToRem(-132);
    position: relative;
    z-index: 1;
}

.contact-offices__heading {
    width: pxToRem($width-desktop);
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    padding-left: 0;
}

.contact-offices__card-grid-wrapper {
    position: relative;
    background-color: $very-light-pink-three;
}

.contact-offices__card-grid-wrapper::before {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(50);
    background-color: $dark-indigo;
    position: absolute;
    top: 0;
    left: 0;
}

.contact-offices__card-grid-wrapper .card-grid {
    padding-left: 0;
    padding-right: 0;
}

.contact-offices__card-grid-wrapper .card-grid__list {
    margin-left: pxToRem(-10);
    margin-right: pxToRem(-10);
    justify-content: flex-start;
}

.contact-offices__card-grid-wrapper .card-grid__item {
    margin-left: pxToRem(10);
    margin-right: pxToRem(10);
}

.contact-offices__card-grid-wrapper .card-grid__details {
    padding: pxToRem(25) pxToRem(30);
}

.contact-offices__office {
    position: relative;
    z-index: 1;
    width: pxToRem($width-desktop);
    margin-left: auto;
    margin-right: auto;
}

.contact-offices__card-grid-wrapper .card-grid__link {
    height: 100%;
}

.contact-offices__card-grid-wrapper .card-grid__item {
    min-height: pxToRem(360);
}