/* Module: S */
.rich-slider {
  margin-left: calc((100vw - 100%) / -2);
  margin-top: pxToRem(31);
  width: 100vw;
  z-index: 1;
}

.rich-slider__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.rich-slider__item {
  padding-top: pxToRem(49);
}

.rich-slider__item-details {
  background-color: $very-light-pink;
  color: $black-two;
  padding: pxToRem($padding-mobile);
  position: relative;
  text-align: center;
}

.rich-slider__item-type {
  color: $rouge;
  display: block;
  font-size: pxToRem(20);
  text-align: left;
  font-family: $font-neustadt;
}

.rich-slider__item-thumbnail {
  border: solid pxToRem(4) $white;
  border-radius: pxToRem(50);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
  height: pxToRem(98);
  left: calc(50% - #{pxToRem(49)});
  position: absolute;
  top: pxToRem(-49);
  width: pxToRem(98);
}

.rich-slider__item-title {
  font-family: $font-open-sans;
  font-size: pxToRem(20);
  font-weight: 400;
  padding-top: pxToRem(20);
}

.rich-slider__item-description {
  font-family: $font-open-sans;
  font-size: pxToRem(14);
  line-height: 2;
  margin-bottom: pxToRem(35);
}

.rich-slider__item-details--bg-grey {
  background-color: $very-light-pink;
}

.rich-slider__item-author {
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  line-height: 1.67;
  font-weight: bold;
  color: $brownish-grey;
  text-transform: uppercase;
  margin-bottom: pxToRem(42);
  display: block;
}

.rich-slider__item-more {
  display: block;
  color: $black-two;
  font-family: $font-open-sans;
  font-size: pxToRem(12);
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: pxToRem(30);
}

.rich-slider__item-more .icon-arrow-right-thin {
  height: pxToRem(18);
  left: pxToRem(7);
  position: relative;
  top: pxToRem(5);
  width: pxToRem(18);
}

.rich-slider .slick-dots {
  position: absolute;
  top: pxToRem(72);
  right: pxToRem($padding-mobile);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.rich-slider .slick-dots li {
  display: inline-block;
  padding-left: pxToRem(16);
}

.rich-slider .slick-dots button,
.rich-slider .slick-dots button:focus {
  width: pxToRem(10);
  height: pxToRem(10);
  border: pxToRem(2) solid $white;
  text-indent: -999em;
  background-color: $light-grey-blue;
  padding: 0;
  border-radius: pxToRem(5);
}

.rich-slider .slick-active button {
  border-color: $lipstick;
  background-color: $lipstick;
}

.rich-slider .slick-dots button:hover,
.rich-slider .slick-dots .slick-active button:focus {
  background-color: $lipstick;
  border-color: $lipstick;
}

