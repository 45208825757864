/* Module: S */
.header-search__toggle {
  display: none;
}
.header-search__form {
  align-items: center;
  border-bottom: pxToRem(1) solid #113671;
  display: flex;
  height: pxToRem(76);
  justify-content: space-between;
}

.header-search__input {
  background-color: transparent;
  border: none;
  color: $white;
  height: pxToRem(76);
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);
  width: 100%;
}

.header-search__input::placeholder {
  color: $white;
}

.header-search__submit {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: pxToRem(76);
  justify-content: center;
  width: pxToRem(60);
}

.header-search__submit .icon-search {
  height: pxToRem(18);
  width: pxToRem(17);
  fill: $white;
}

.main-header--submenu-open .header-search__toggle .icon-search {
  fill: $white;
}
