// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #222;

// Colors from Zeplin
$black: #000000;
$black-0: rgba(0, 0, 0, 0);
$light-navy: #15397e;
$dark-indigo: #0a1c42;
$white: #ffffff;
$very-light-pink: #f7f6f6;
$lipstick: #e3183d;
$brown-grey: #8b8b8b;
$black-two: #0b0b0b;
$rouge: #b71337;
$brownish-grey: #696969;
$pinkish-red: #e52447;
$greyish-brown: #4a4a4a;
$light-grey-blue: #b3b6b8;
$watermelon: #f5515f;
$carmine: #9f041b;
$light-navy-0: rgba(21, 57, 126, 0);
$dark-indigo-two: #0e2655;
$white-two: #fbfbfb;
$black-three: #1a1a1a;
$light-navy-two: #123066;
$white-three: #fafafa;
$very-light-pink-two: #dedede;
$light-navy-three: #15387f;
$lipstick-two: #e2173d;
$white-four: #fefefe;
$very-light-pink-three: #e4e4e4;
$purple-grey: #696364;
$white-0: rgba(255, 255, 255, 0);
$greyish-brown-two: #3c3c3c;
$black-four: #0e0e0e;
$dark-indigo-three: #0e2653;
$very-light-blue: #e7e8ea;
$dark-indigo-four: #061228;
$very-light-pink-four: #f4f3f3;
$dark-indigo-five: #0d2555;
$very-light-pink-five: #d8d8d8;
$black-five: #242424;
$very-light-pink-six: #e0e0e0;
$light-blue-grey: #d0d2d6;

// Custom color variables (outside of zeplin)

// -- Selection highlighting
$content-selection-highlight: #b3d4fc;

$padding-mobile: 20;
$width-desktop: 1180;