/* Module: S */
.text-image {
    background-color: $very-light-pink-three;
    margin-left: calc((100vw - 100%) / -2);
    width: 100vw;
}

.text-image--no-background {
    width: 100%;
    margin-left: 0;
    background-color: transparent;
    .text-image__text {
        width: 100%;
    }

    .text-image__image {
        width: 100%;
        margin-left: 0;
    }
}

.text-image__header {
    color: $black-two;
    font-size: pxToRem(26);
    line-height: pxToRem(34);
    font-family: $font-open-sans;
    font-weight: normal;

}

.text-image__text {
    width: 100vw;
    padding: pxToRem(30) pxToRem(20);
    p {
        color: $black-two;
        font-size: pxToRem(14);
        line-height: pxToRem(28);
        font-family: $font-open-sans;
        margin: pxToRem(30) 0;
    }
}

.text-image__image {
    width: 100vw;
    max-width: none;
    display: block;
    margin-left: calc((100vw - 100%) / -2);
    img {
        width: 100%;
        display: block;
        object-fit: cover;
    }
}

.text-image--no-background .text-image__text {
    padding: 0;
}

/*
.text-image--no-background .text-image__image {
    padding-top: pxToRem(40);
}
*/