/* Module: S */
.image-row {
  margin-top: pxToRem(30);
  margin-bottom: pxToRem(77);
  max-width: pxToRem($width-desktop);

}
.image-row__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: pxToRem($width-desktop);
}

.image-row img {
  flex-shrink: 1;
}

.image-row__image-wrapper {
  margin-left: pxToRem(10);
  margin-right: pxToRem(10);
  width: 100%;
}

.image-row__image-wrapper:first-of-type {
  margin-left: 0;
}

.image-row__image-wrapper:last-of-type {
  margin-right: 0;
}